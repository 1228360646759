<template>
    <div><v-text-field v-model="model.firstName" label="Fornavn" :rules="rules"></v-text-field></div>
    <div><v-text-field v-model="model.lastName" label="Etternavn" :rules="rules"></v-text-field></div>
    <div><v-text-field v-model="model.email" label="E-post" :rules="emailRules"></v-text-field></div>
    <phone-input v-model="model.phone" :phone-codes="phoneCountryCodes" />
</template>

<script lang="ts" setup>
import { Profile } from './profilesModels';
import PhoneInput from '../shared/components/PhoneInput.vue';
import { useVModel } from '@vueuse/core';
import { isRequired, emailFormat } from '../shared/formValidationRules';
import { PhoneCountryCode } from '../shared/models';

interface Props {
    profile: Profile;
    phoneCountryCodes: Array<PhoneCountryCode>;
}

type Emits = {
    (e: 'update:profile', value: Profile): void;
};

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const rules = [isRequired];
const emailRules = [...rules, emailFormat];

const model = useVModel(props, 'profile', emit);
</script>
