﻿export interface AuditEvent {
    action: AuditEventAction;
    createdAt: Date;
    user: string;
    profileId?: string;
    jsonData?: string;
}

export enum AuditEventAction {
    Unknown,
    View,
    Create,
    Update
}
