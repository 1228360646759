<template>
    <v-form ref="form" v-model="isFormValid">
        <v-dialog v-model="model" width="auto">
            <v-card min-width="500" title="Endre passord">
                <v-card-text>
                    <div>
                        <v-text-field v-model="formModel.password" label="Passord" :rules="passwordRule" type="password"></v-text-field>
                    </div>
                    <div>
                        <v-text-field
                            v-model="formModel.passwordConfirm"
                            label="Bekreft passord"
                            :rules="passwordConfirmationRule"
                            type="password"
                        ></v-text-field>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" flat @click="model = false">Avbryt</v-btn>
                    <v-btn color="green darken-1" flat :disabled="disabled" @click="changePassword()">Endre</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core';
import { ref, watch } from 'vue';

interface Props {
    modelValue: boolean;
}

type Emits = {
    (e: 'modelValue', value: boolean): void;
    (e: 'changePassword', password: string): void;
};

const emit = defineEmits<Emits>();
const props = defineProps<Props>();
const model = useVModel(props, 'modelValue', emit);

const isFormValid = ref<boolean | null>();
const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: { id: string | number; errorMessages: string[] }[];
        }>;
    }
>();
const disabled = ref(true);

interface ChangePasswordModel {
    password: string;
    passwordConfirm: string;
}

const formModel = ref<ChangePasswordModel>({ password: '', passwordConfirm: '' });
const passwordRule = [(val: string) => /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d\-+_!@#$%^&*.,?]{10,}$/.test(val) || 'Passordet må være på minst 10 tegn, og ha både store og små bokstaver'];
const passwordConfirmationRule = [(val: string) => formModel.value.password === val || 'Passordene samsvarer ikke'];

watch(isFormValid, newValue => {
    if (newValue === null) {
        disabled.value = false;
    } else {
        disabled.value = !newValue;
    }
});

async function changePassword() {
    const validation = await form.value?.validate();
    if (!validation?.valid) return;

    emit('changePassword', formModel.value.password);
    model.value = false;
    formModel.value = { password: '', passwordConfirm: '' };
}
</script>
