﻿import { Phone } from '@/features/shared/models';

const defaultLocale = 'nb-NO';
const defaultTimeZone = 'Europe/Oslo';

export const useFormatters = function () {
    function toFullPhone(phone: Phone) {
        return `${phone.countryCode} ${phone.number}`;
    }

    function toLocaleDateTime(date: Date | number, locale = defaultLocale, timeZone = defaultTimeZone) {
        if (!date) return '';

        const formatter = new Intl.DateTimeFormat(locale, {
            timeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        return formatter
            .formatToParts(new Date(date))
            .map(({ type, value }) => {
                if (type === 'literal' && value === ', ') {
                    return ' ';
                }
                return value;
            })
            .join('');
    }

    return {
        toFullPhone,
        toLocaleDateTime
    };
};
