export const ProfileRels = {
    GetProfiles: 'getProfiles',
    GetProfile: 'getProfile',
    UpdateProfile: 'updateProfile',
    CreateProfile: 'createProfile',
    DeleteProfile: 'deleteProfile',
    GetAuditEvents: 'getAuditEvents',
    GetTravelCards: 'getTravelCards',
    GetIssues: 'getIssues',
    CustomerExternalUrl: 'customerExternalUrl',
    UserExternalUrl: 'userExternalUrl',
    GetProfileUser: 'getProfileUser',
    UpdateProfileUser: 'updateProfileUser',
    SendEmailConfirmation: 'sendEmailConfirmation',
    SendForgotPassword: 'sendForgotPassword',
    UpdateProfileUserCredentials: 'updateProfileUserCredentials'
};
