﻿<template>
    <v-container>
        <v-row>
            <v-col>
                <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
                <v-btn variant="tonal" :to="backRoute" prepend-icon="mdi-arrow-left-circle">Tilbake til listen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="profile-form">
                    <v-card-text>
                        <v-form ref="form" v-model="isFormValid" :class="{ loading: loading }" @submit.prevent="submit">
                            <profile-details v-model:profile="profile" :phone-country-codes="phoneCountryCodes" />
                            <br />
                            <v-btn type="submit" :disabled="submitDisabled" color="primary">Submit</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import ProfileDetails from './ProfileDetails.vue';
import { ref, onMounted, watch, computed } from 'vue';
import { PhoneCountryCode } from '@/features/shared/models';
import { Profile } from './profilesModels';
import { useCommonStore } from '@/features/shared/commonStore';
import { useProfilesStore } from './profilesStore';
import { useRouter } from 'vue-router';
import { ProfileRels } from './rels';
import { useEmitter, Events, AlertTypes } from '@/features/shared/useEmitter';
import { getErrors } from '../shared/ErrorHelper';

let profile = ref({ phone: { countryCode: '+47', number: '' } } as Profile);
const phoneCountryCodes = ref<Array<PhoneCountryCode>>([]);
const commonStore = useCommonStore();
const store = useProfilesStore();
const router = useRouter();
const isFormValid = ref<boolean | null>();
const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: { id: string | number; errorMessages: string[] }[];
        }>;
    }
>();
let disabled = false;
const eventBus = useEmitter();
const loading = ref(false);
const backRoute = ref({ name: ProfileRels.GetProfiles, query: {} });

const breadcrumbs = [
    {
        title: 'Profiler',
        disabled: false,
        to: {
            name: ProfileRels.GetProfiles
        }
    },
    {
        title: 'Ny profil'
    }
];

onMounted(async () => {
    loadBackRoute();
    store.clearProfile();
    phoneCountryCodes.value = await commonStore.getCountryPhoneCodes();
});

watch(
    profile,
    () => {
        store.currentProfile = profile.value;
    },
    { deep: true }
);

watch(isFormValid, newValue => {
    if (newValue === null) {
        disabled = false;
    } else {
        disabled = !newValue;
    }
});

const submitDisabled = computed(() => {
    return !store.isValidProfile || disabled;
});

function loadBackRoute() {
    backRoute.value = {
        name: ProfileRels.GetProfiles,
        query: { page: store.paging.pageNumber, pageSize: store.paging.pageSize }
    };
}

async function submit() {
    loading.value = true;
    const validation = await form.value?.validate();
    if (!validation?.valid) return;

    try {
        await store.createProfile();
        loading.value = false;
        const alertContent = { type: AlertTypes.success, text: 'Profilen er opprettet' };
        eventBus.emitter.emit(Events.alert, alertContent);
        router.push({ name: ProfileRels.GetProfiles });
    } catch (err: any) {
        loading.value = false;
        const data = err.response.data;
        const errors = getErrors(data);

        if (errors !== null) {
            eventBus.emitter.emit(Events.alert, errors);
        } else {
            throw err;
        }
    }
}
</script>

<style scoped>
.profile-form {
    width: 100%;
    max-width: 700px;
}

.loading {
    opacity: 0.5;
}
</style>
