<template>
    <v-alert class="alert" closable :model-value="alertModel" :text="alertText" :type="alertType" position="fixed" elevation="2" />
</template>

<script lang="ts" setup>
import { ref, defineExpose } from 'vue';
import { AlertContent } from '../models';
const alertModel = ref(false);
const alertType = ref<'success' | 'error'>();
const alertText = ref('');

function setAlert(alertContent: AlertContent) {
    alertText.value = alertContent.text;
    alertType.value = alertContent.type;

    alertModel.value = true;
    window.setTimeout(() => {
        alertModel.value = false;
    }, 3000);
}

defineExpose({ setAlert });
</script>

<style lang="scss" scoped>
.alert {
    position: fixed;
    top: 70px;
    left: 50%;
    z-index: 905;
    width: 90%;
    transform: translate(-50%, 0);
    max-width: 1000px;
}
</style>
