﻿import { createRouter, createWebHistory } from 'vue-router';
import { ProfileRoutes } from '@/features/profiles/routes';
import AccessDenied from '@/features/shared/AccessDenied.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...ProfileRoutes,
        {
            path: '/accessdenied',
            name: 'AccessDenied',
            component: AccessDenied
        }
    ]
});

export default router;
