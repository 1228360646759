export interface BaseResource {
    links: Array<Link>;
}

export interface Resource<T> extends BaseResource {
    data: T | null;
}

export interface ListResource<T> extends BaseResource {
    data: T[];
    paging: Paging;
}

export interface Paging {
    pageNumber: number;
    pageSize: number;
    totalItemCount: number;
}

export const DefaultPageSize = 30;

export const DefaultPerPageOptions = [
    { value: 10, title: '10' },
    { value: 30, title: '30' },
    { value: 100, title: '100' }
];

export const DefaultPaging = {
    pageNumber: 1,
    pageSize: DefaultPageSize,
    totalItemCount: 0
} as Paging;

export interface Link {
    rel: string;
    href: string;
    method: string;
}

export interface PhoneCountryCode {
    code: string;
    name: string;
}

export interface Phone {
    countryCode: string;
    number: string;
}

export interface AlertContent {
    type: 'success' | 'error';
    text: string;
}
