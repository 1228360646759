﻿<template>
    <v-container>
        <v-row>
            <v-col>
                <v-row>
                    <v-col><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-col>
                    <v-col><v-btn class="add-new" color="primary" @click="goToCreateNew()">Ny profil</v-btn></v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                <v-text-field
                                    v-model="store.searchPhrase"
                                    append-inner-icon="mdi-magnify"
                                    variant="underlined"
                                    label="Søk"
                                    single-line
                                    hide-details
                                    clearable
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table-server
                                :page="store.paging.pageNumber"
                                :headers="headers"
                                :items="store.profiles"
                                :items-per-page="store.paging.pageSize"
                                :items-per-page-options="DefaultPerPageOptions"
                                :items-length="store.paging.totalItemCount"
                                :loading="isLoading"
                                :search="store.searchPhrase"
                                @update:itemsPerPage="onItemsPerPageChange"
                                @update:page="onPageChange"
                            >
                                <template #item.phone="{ item }">
                                    {{ formatters.toFullPhone(item.raw.phone) }}
                                </template>
                                <template #item.gdprConsentAt="{ item }">
                                    {{ formatDate(item.raw.gdprConsentAt) }}
                                </template>
                                <template #item.controls="{ item }">
                                    <router-link :to="{ name: ProfileRels.UpdateProfile, params: { id: item.raw.id } }">
                                        <v-icon class="edit" icon="mdi-account-edit" />
                                    </router-link>
                                </template>
                            </v-data-table-server>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { useProfilesStore } from '@/features/profiles/profilesStore';
import { onMounted, ref, watch } from 'vue';
import { useFormatters } from '@/features/shared/formatters';
import { ProfileRels } from '@/features/profiles/rels';
import { useRouter, useRoute } from 'vue-router';
import { VDataTableServer } from 'vuetify/labs/components';
import { DefaultPaging, DefaultPerPageOptions } from '@/features/shared/models';
import { storeToRefs } from 'pinia';
import _ from 'lodash';

const store = useProfilesStore();
const { searchPhrase } = storeToRefs(store);
const formatters = useFormatters();
const router = useRouter();
const route = useRoute();

const breadcrumbs = [
    {
        title: 'Profiler'
    }
];

const headers = [
    {
        title: 'Fornavn',
        key: 'firstName',
        sortable: false
    },
    {
        title: 'Etternavn',
        key: 'lastName',
        sortable: false
    },
    {
        title: 'E-post',
        key: 'email',
        sortable: false
    },
    {
        title: 'Telefon',
        key: 'phone',
        sortable: false
    },
    {
        title: 'GDPR-samtykke',
        key: 'gdprConsentAt',
        sortable: false
    },
    {
        title: '',
        key: 'controls',
        sortable: false
    }
];

const isLoading = ref(true);
let initialized = false;

onMounted(async () => {
    isLoading.value = true;

    if (route.query?.search) {
        store.searchPhrase = route.query.search.toString();
    } else {
        store.searchPhrase = '';
    }
    if (route.query?.page) {
        store.paging.pageNumber = route.query.page as any;
    } else {
        store.paging.pageNumber = DefaultPaging.pageNumber;
    }
    if (route.query?.pageSize) {
        store.paging.pageSize = route.query.pageSize as any;
    } else {
        store.paging.pageSize = DefaultPaging.pageSize;
    }

    await store.loadProfiles();

    isLoading.value = false;
    initialized = true;
});

async function onPageChange(pageNumber: number) {
    if (!initialized) return;

    isLoading.value = true;

    store.paging.pageNumber = pageNumber;

    await updateRouteQuery();
    await store.loadProfiles();

    isLoading.value = false;
}

async function onItemsPerPageChange(pageSize: number) {
    if (!initialized) return;

    isLoading.value = true;

    store.paging.pageSize = pageSize;

    await updateRouteQuery();
    await store.loadProfiles();

    isLoading.value = false;
}

watch(
    searchPhrase,
    _.debounce(async () => {
        if (!initialized) return;

        isLoading.value = true;

        await updateRouteQuery();
        await store.loadProfiles();

        isLoading.value = false;
    }, 500)
);

async function updateRouteQuery() {
    const query = { page: store.paging.pageNumber, pageSize: store.paging.pageSize } as any;
    if (store.searchPhrase) query.search = store.searchPhrase;
    await router.push({ ...route, query: query });
}

function goToCreateNew() {
    router.push({ name: ProfileRels.CreateProfile });
}

function formatDate(date: Date) {
    return formatters.toLocaleDateTime(date);
}
</script>

<style scoped>
.edit {
    color: #000000;
}

.add-new {
    float: right;
    margin-top: 10px;
}
</style>
