<template>
    <v-row>
        <v-col>
            <v-skeleton-loader v-if="props.loading"></v-skeleton-loader>

            <v-card v-else>
                <v-data-table
                    :headers="headers"
                    :items="props.auditEvents"
                    items-per-page="10"
                    :items-per-page-options="DefaultPerPageOptions"
                    :search="filter"
                    :custom-filter="filterAction"
                    :item-value="(item: any) => `${item.createdAt}`"
                    show-expand
                >
                    <template #column.action="{ column }">
                        <v-select
                            v-model="filter"
                            :label="column.title"
                            :items="selectItems"
                            variant="underlined"
                            class="filter-select"
                        ></v-select>
                    </template>
                    <template #item.createdAt="{ item }">
                        {{ formatDate(item.raw.createdAt) }}
                    </template>
                    <template #expanded-row="{ item }">
                        <td></td>
                        <td colspan="3" class="expand-content">
                            <pre>{{ JSON.stringify(JSON.parse(item.raw['jsonData']), null, '\t') }}</pre>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { VSkeletonLoader, VDataTable } from 'vuetify/labs/components';
import { useFormatters } from '@/features/shared/formatters';
import { ref } from 'vue';
import { AuditEventAction, AuditEvent } from '@/features/auditing/auditingModels';
import { DefaultPerPageOptions } from '@/features/shared/models';

interface Props {
    auditEvents: Array<AuditEvent>;
    loading: boolean;
}
const props = defineProps<Props>();
const formatters = useFormatters();

const filterPlaceholder = 'Alle';
const filter = ref(filterPlaceholder);
const selectItems = [
    filterPlaceholder,
    AuditEventAction[AuditEventAction.View],
    AuditEventAction[AuditEventAction.Create],
    AuditEventAction[AuditEventAction.Update]
];

const headers = [
    {
        title: '',
        key: 'data-table-expand',
        width: '1%'
    },
    {
        title: 'Dato',
        key: 'createdAt',
        sortable: true,
        firstSortType: 'desc',
        width: '250px'
    },
    {
        title: 'Handling',
        key: 'action',
        width: '250px'
    },
    {
        title: 'Bruker',
        key: 'user',
        sortable: true
    }
];

function filterAction(value: string, query: string) {
    if (query === filterPlaceholder) return;
    return value === query;
}

function formatDate(date: Date) {
    return formatters.toLocaleDateTime(date);
}
</script>

<style class="scss" scoped>
.expand-content {
    padding: 12px;
}

.filter-select {
    margin: 10px 0;
}

.filter-select :deep(.v-input__details) {
    display: none;
}
</style>
