import { defineStore } from 'pinia';
import { useProfilesStore } from '../profiles/profilesStore';
import { useAxios } from '@/features/shared/axios';
import { ProfileRels } from '@/features/profiles/rels';
import { getLink } from '@/features/shared/LinkHelper';
import { TravelCard } from './travelCardsModels';

export const useTravelCardsStore = defineStore('travelCards', {
    state: () => ({
        currentTravelCards: new Array<TravelCard>()
    }),
    actions: {
        async loadTravelCards() {
            const profilesStore = useProfilesStore();
            const axios = useAxios();
            const link = getLink(ProfileRels.GetTravelCards, profilesStore.getProfileLinks);
            const response = await axios.requestByLink(link);
            this.currentTravelCards = response.data.data;
        }
    }
});
