<template>
    <v-row>
        <v-col>
            <v-skeleton-loader v-if="props.loading"></v-skeleton-loader>
            <v-card v-else>
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-inner-icon="mdi-magnify"
                        variant="underlined"
                        label="Søk"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="props.issues"
                    :search="search"
                    items-per-page="10"
                    :items-per-page-options="DefaultPerPageOptions"
                >
                    <template #item.body="{ item }">
                        <div class="body">{{ item.columns.body }}</div>
                    </template>
                    <template #item.createdAt="{ item }">
                        {{ formatDate(item.raw.createdAt) }}
                    </template>
                    <template #item.modifiedAt="{ item }">
                        {{ formatDate(item.raw.modifiedAt) }}
                    </template>
                    <template #item.occuredAt="{ item }">
                        {{ formatDate(item.raw.occuredAt) }}
                    </template>
                    <template #item.link="{ item }">
                        <a class="issue-link" :href="getIssueLink(item.raw.links)" target="blank">
                            <v-tooltip activator="parent" location="start">Til Boomerang</v-tooltip>
                            <v-icon icon="mdi-open-in-new" />
                        </a>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { VSkeletonLoader, VDataTable } from 'vuetify/labs/components';
import { getLink } from '@/features/shared/LinkHelper';
import { IssueRels } from '@/features/issues/rels';
import { IssueSummary } from '@/features/issues/issuesModels';
import { useFormatters } from '@/features/shared/formatters';
import { DefaultPerPageOptions, Link } from '@/features/shared/models';
import { ref } from 'vue';

interface Props {
    issues: Array<IssueSummary>;
    loading: boolean;
}
const props = defineProps<Props>();
const formatters = useFormatters();
const search = ref('');
const headers = [
    {
        title: 'Emne',
        key: 'subject'
    },
    {
        title: 'Brødtekst',
        key: 'body',
        sortable: false
    },
    {
        title: 'Status',
        key: 'status'
    },
    {
        title: 'Opprettet kl',
        key: 'createdAt'
    },
    {
        title: 'Endret kl',
        key: 'modifiedAt'
    },
    {
        title: 'Oppstod kl',
        key: 'occuredAt'
    },
    {
        title: 'Til Boomerang',
        key: 'link',
        align: 'center',
        sortable: false
    }
];

function formatDate(date: Date) {
    return formatters.toLocaleDateTime(date);
}

function getIssueLink(links: Array<Link>) {
    const issueLink = getLink(IssueRels.GetExternalLink, links);
    return issueLink.href;
}
</script>

<style lang="scss" scoped>
.body {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.issue-link {
    color: #000000;
}
</style>
