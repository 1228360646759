﻿import { defineStore } from 'pinia';
import { AuditEvent } from '@/features/auditing/auditingModels';
import { useAxios } from '@/features/shared/axios';
import { ProfileRels } from '@/features/profiles/rels';
import { getLink } from '@/features/shared/LinkHelper';
import { useProfilesStore } from '@/features/profiles/profilesStore';

export const useAuditingStore = defineStore('auditing', {
    state: () => ({ auditEvents: new Array<AuditEvent>() }),
    actions: {
        async loadCurrentProfileEvents() {
            const profileStore = useProfilesStore();
            const axios = useAxios();
            const link = getLink(ProfileRels.GetAuditEvents, profileStore.currentProfileLinks);
            const response = await axios.requestByLink(link);
            this.auditEvents = response.data.data;
        }
    }
});
