import { Link } from '@/features/shared/models';

export function getLink(rel: string, links: Array<Link>) {
    if (links) {
        const foundLink = links.find(x => x.rel == rel);
        if (foundLink) return foundLink;
        else throw new Error(`No link found by rel: '${rel}'`);
    } else {
        throw new Error('Links array is empty');
    }
}

export function hasLink(rel: string, links: Array<Link>) {
    if (links) {
        const foundLink = links.find(x => x.rel == rel);
        if (foundLink) return true;
    }
    return false;
}
