﻿import { defineStore } from 'pinia';
import { Claim, User } from '@/features/authentication/userModels';
import axios from 'axios';
import { useAxios } from '@/features/shared/axios';
import { useCommonStore } from '@/features/shared/commonStore';
import { getLink } from '@/features/shared/LinkHelper';
import { UserRels } from '@/features/authentication/rels';
import { Profile } from '@/features/profiles/profilesModels';

const initialUser: User = { isLoggedIn: false, name: '', logoutUrl: '' };
const initialClaims: Claim[] = [];

export const useUserStore = defineStore('user', {
    state: () => ({ user: initialUser, profile: undefined as Profile | undefined, claims: initialClaims }),
    getters: {
        getClaim: state => {
            return (name: string) => state.claims.find(x => x.type == name)!.value;
        }
    },
    actions: {
        async loadUser() {
            const { data, status } = await axios.get<Claim[]>('/bff/user', {
                headers: {
                    'X-CSRF': '1'
                },
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status == 401; // allow 401 - silent-login will handle it
                }
            });

            if (status != 200) {
                return;
            }

            this.claims = data;
            this.user = {
                isLoggedIn: true,
                name: this.getClaim('name'),
                logoutUrl: this.getClaim('bff:logout_url')
            };
        },

        async loadUserProfile() {
            if (!this.user.isLoggedIn) return;

            const axios = useAxios();
            const commonStore = useCommonStore();
            const link = getLink(UserRels.GetMyProfile, commonStore.links);
            const { data, status } = await axios.requestByLink(link, {
                headers: {
                    'X-CSRF': '1'
                },
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status == 404; // allow 404 - profile not created
                }
            });

            if (status != 200) {
                return;
            }

            this.profile = data.data;
        }
    }
});
