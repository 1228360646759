import mitt from 'mitt';
const emitter = mitt();

export function useEmitter() {
    return {
        emitter
    };
}

export const Events = {
    alert: 'alert'
};

export const AlertTypes = {
    error: 'error' as 'error',
    success: 'success' as 'success'
};
