import { AlertContent } from './models';
import { AlertTypes } from './useEmitter';

export function getErrors(data: any): AlertContent | null {
    const errors: Array<string> = [];
    let alertContent: AlertContent | null = null;

    Object.keys(data).forEach(function (error) {
        errors.push(data[error].join('; '));
    });

    if (errors.length > 0) {
        const errorText = errors.join('; ');
        alertContent = { type: AlertTypes.error, text: errorText };
    }

    return alertContent;
}
