<template>
    <v-row>
        <v-col>
            <v-skeleton-loader v-if="props.loading"></v-skeleton-loader>
            <v-card v-else>
                <v-data-table :headers="headers" :items="props.travelCards">
                    <template #item.link="{ item }">
                        <a class="card-link" :href="getTravelCardLink(item.raw.links)" target="blank">
                            <v-tooltip activator="parent" location="start">Til Boomerang</v-tooltip>
                            <v-icon icon="mdi-open-in-new" />
                        </a>
                    </template>
                    <template #bottom></template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { VSkeletonLoader, VDataTable } from 'vuetify/labs/components';
import { getLink } from '@/features/shared/LinkHelper';
import { TravelCardRels } from '@/features/travelCards/rels';
import { TravelCard } from '@/features/travelCards/travelCardsModels';
import { Link } from '../shared/models';

interface Props {
    travelCards: Array<TravelCard>;
    loading: boolean;
}
const props = defineProps<Props>();

const headers = [
    {
        title: 'Navn',
        key: 'name',
        sortable: false
    },
    {
        title: 'Nummer',
        key: 'number',
        sortable: false
    },
    {
        title: 'Status',
        key: 'status',
        sortable: false
    },
    {
        title: 'Utløpstid',
        key: 'expireAt',
        sortable: false
    },
    {
        title: 'Til Boomerang',
        key: 'link',
        align: 'center',
        sortable: false
    }
];

function getTravelCardLink(links: Array<Link>) {
    const cardLink = getLink(TravelCardRels.GetExternalLink, links);
    return cardLink.href;
}
</script>

<style lang="scss" scoped>
.card-link {
    color: #000000;
}
</style>
