﻿<template>
    <v-container>
        <v-row>
            <v-col>
                <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
                <v-btn variant="tonal" :to="backRoute" prepend-icon="mdi-arrow-left-circle">Tilbake til listen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="loading.profile">
                <v-row>
                    <v-skeleton-loader class="profile-loader" type="heading, list-item, list-item, list-item"> </v-skeleton-loader>
                </v-row>
            </v-col>
            <v-col v-else-if="store.currentProfile.id">
                <v-tabs v-model="tab">
                    <v-tab value="form">Profiler</v-tab>
                    <v-tab value="cards">Reisekort</v-tab>
                    <v-tab value="issues">Henvendelser</v-tab>
                    <v-tab value="audit">Log</v-tab>
                </v-tabs>
                <v-window v-model="tab" class="profile-edit-window" :touch="false">
                    <v-window-item value="form">
                        <v-card class="profile-form">
                            <v-toolbar>
                                <v-btn :href="getLink(ProfileRels.CustomerExternalUrl, store.getProfileLinks).href" target="blank"
                                    >Boomerang <v-icon icon="mdi-open-in-new"
                                /></v-btn>
                                <v-btn
                                    v-if="hasLink(ProfileRels.UserExternalUrl, store.getProfileLinks)"
                                    :href="getLink(ProfileRels.UserExternalUrl, store.getProfileLinks).href"
                                    target="blank"
                                    >Identity <v-icon icon="mdi-open-in-new"
                                /></v-btn>
                                <v-spacer></v-spacer>
                                <v-menu v-if="userMenuItems.length > 0">
                                    <template #activator="{ props }">
                                        <v-btn icon="mdi-dots-vertical" density="comfortable" class="menu-button" v-bind="props"></v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="(item, i) in userMenuItems" :key="i" @click="item.action()">
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-toolbar>
                            <v-card-text>
                                <v-form ref="form" v-model="isFormValid" :class="{ loading: loading.submit }" @submit.prevent="submit">
                                    <profile-details v-model:profile="store.currentProfile" :phone-country-codes="phoneCountryCodes" />
                                    <br />
                                    <div>GDPR-samtykke: {{ formatDate(store.currentProfile.gdprConsentAt) }}</div>
                                    <br />
                                    <div>
                                        Tofaktorautentisering:
                                        <strong>{{ store.currentProfileUser.twoFactorEnabled ? 'Aktivert' : 'Deaktivert' }}</strong>
                                    </div>
                                    <div>
                                        E-post bekreftet: <strong>{{ store.currentProfileUser.emailConfirmed ? 'Ja' : 'Nei' }}</strong>
                                    </div>
                                    <div>
                                        Telefon bekreftet:
                                        <strong>{{ store.currentProfileUser.phoneNumberConfirmed ? 'Ja' : 'Nei' }}</strong>
                                    </div>
                                    <div>
                                        Antall tilgang mislyktes: <strong>{{ store.currentProfileUser.accessFailedCount }}</strong>
                                    </div>
                                    <div>
                                        Slutt på lockout: <strong>{{ formatDate(store.currentProfileUser.lockoutEnd) }}</strong>
                                    </div>
                                    <br />
                                    <div class="controls">
                                        <v-btn type="submit" :disabled="disabled" color="primary">Submit</v-btn>
                                        <v-btn
                                            v-if="hasLink(ProfileRels.DeleteProfile, store.currentProfileLinks)"
                                            density="comfortable"
                                            icon="mdi-delete"
                                            @click="confirmDialog = true"
                                        ></v-btn>
                                        <v-dialog v-model="confirmDialog" persistent max-width="290">
                                            <v-card>
                                                <v-card-text>Er du sikker på at du vil slette denne profilen?</v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="green darken-1" flat @click="confirmDialog = false">Avbryt</v-btn>
                                                    <v-btn color="green darken-1" flat @click="deleteProfile()">Ok</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </div>
                                </v-form>
                                <profile-change-password v-model="changePasswordDialog" @change-password="changePassword"></profile-change-password>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                    <v-window-item value="cards">
                        <travel-cards :loading="loading.travelCards" :travel-cards="travelCardsStore.currentTravelCards" />
                    </v-window-item>
                    <v-window-item value="issues">
                        <profile-issues :loading="loading.issues" :issues="issuesStore.currentIssues" />
                    </v-window-item>
                    <v-window-item value="audit">
                        <profile-audit :loading="loading.audit" :audit-events="auditStore.auditEvents" />
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { useProfilesStore } from '@/features/profiles/profilesStore';
import { useRoute, useRouter } from 'vue-router';
import { nextTick, onMounted, ref, watch } from 'vue';
import { PhoneCountryCode } from '@/features/shared/models';
import ProfileDetails from './ProfileDetails.vue';
import ProfileChangePassword from './ProfileChangePassword.vue';
import { useCommonStore } from '@/features/shared/commonStore';
import { ProfileRels } from './rels';
import { useEmitter, Events, AlertTypes } from '@/features/shared/useEmitter';
import { useAuditingStore } from '@/features/auditing/auditingStore';
import TravelCards from '@/features/travelCards/TravelCards.vue';
import { useTravelCardsStore } from '@/features/travelCards/travelCardsStore';
import ProfileIssues from '@/features/issues/ProfileIssues.vue';
import { useIssuesStore } from '@/features/issues/issuesStore';
import { getLink, hasLink } from '@/features/shared/LinkHelper';
import ProfileAudit from '@/features/auditing/ProfileAudit.vue';
import { VSkeletonLoader } from 'vuetify/labs/components';
import { getErrors } from '../shared/ErrorHelper';
import { useFormatters } from '@/features/shared/formatters';
import moment from 'moment';

const store = useProfilesStore();
const auditStore = useAuditingStore();
const commonStore = useCommonStore();
const travelCardsStore = useTravelCardsStore();
const issuesStore = useIssuesStore();
const router = useRouter();
const route = useRoute();
const eventBus = useEmitter();
const formatters = useFormatters();

const phoneCountryCodes = ref<Array<PhoneCountryCode>>([]);
const profileId = route.params.id.toString();
const isFormValid = ref<boolean | null>();
const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: { id: string | number; errorMessages: string[] }[];
        }>;
    }
>();
const backRoute = ref({ name: ProfileRels.GetProfiles, query: {} });

let disabled = false;
const confirmDialog = ref(false);
const changePasswordDialog = ref(false);

const breadcrumbs = ref<Array<any>>([
    {
        title: 'Profiler',
        disabled: false,
        to: {
            name: ProfileRels.GetProfiles
        }
    }
]);

const loading = ref({
    submit: false,
    profile: true,
    travelCards: true,
    issues: true,
    audit: true
});

interface UserMenuItem {
    title: string;
    action: Function;
}

const userMenuItems = ref<Array<UserMenuItem>>([]);

const tab = ref('');
watch(tab, newValue => {
    router.replace({ ...route, query: { tab: newValue } });
});

onMounted(async () => {
    if (route.query?.tab) tab.value = route.query.tab.toString();

    loadBackRoute();

    phoneCountryCodes.value = await commonStore.getCountryPhoneCodes();

    await store.loadProfile(profileId);
    breadcrumbs.value.push({ title: store.getFullName });

    loadUserMenu();

    loading.value.profile = false;

    await travelCardsStore.loadTravelCards();
    loading.value.travelCards = false;

    await issuesStore.loadIssues();
    loading.value.issues = false;

    await auditStore.loadCurrentProfileEvents();
    loading.value.audit = false;
});

function loadBackRoute() {
    backRoute.value = {
        name: ProfileRels.GetProfiles,
        query: { page: store.paging.pageNumber, pageSize: store.paging.pageSize }
    };
}

watch(isFormValid, newValue => {
    if (newValue === null) {
        disabled = false;
    } else {
        disabled = !newValue;
    }
});

function loadUserMenu() {
    const user = store.currentProfileUser;
    userMenuItems.value = [];

    if (!user.isIdentityAdmin) {
        if (user.twoFactorEnabled) {
            userMenuItems.value.push({ title: 'Deaktiver 2FA', action: deactivate2fa });
        } else {
            userMenuItems.value.push({ title: 'Aktiver 2FA', action: activate2fa });
        }
    }

    if (hasLink(ProfileRels.SendForgotPassword, store.getProfileLinks)) {
        userMenuItems.value.push({ title: 'Send e-post for tilbakestilling av passord', action: sendForgotPassword });
    }

    if (hasLink(ProfileRels.UpdateProfileUserCredentials, store.getProfileLinks)) {
        userMenuItems.value.push({ title: 'Endre passord', action: openChangePasswordDialog });
    }

    const now = moment.utc();
    const lockoutEnd = moment.utc(user.lockoutEnd);
    if (!!lockoutEnd && lockoutEnd > now) {
        userMenuItems.value.push({ title: 'Låse opp', action: unlockUser });
    }

    if (!user.emailConfirmed) {
        userMenuItems.value.push({ title: 'Send e-postbekreftelse', action: sendEmailConfirmation });
    }
}

async function deactivate2fa() {
    store.currentProfileUser.twoFactorEnabled = false;
    await updateProfileUser('Tofaktorautentisering er deaktivert');
}

async function activate2fa() {
    store.currentProfileUser.twoFactorEnabled = true;
    await updateProfileUser('Tofaktorautentisering er aktivert');
}

async function unlockUser() {
    store.currentProfileUser.lockoutEnd = undefined;
    store.currentProfileUser.accessFailedCount = 0;
    await updateProfileUser('Brukeren har blitt låst opp');
}

async function updateProfileUser(alertText: string) {
    await store.updateProfileUser();
    await nextTick(() => loadUserMenu());
    const alertContent = { type: AlertTypes.success, text: alertText };
    eventBus.emitter.emit(Events.alert, alertContent);
}

async function sendEmailConfirmation() {
    await store.sendEmailConfirmation();
    const alertContent = { type: AlertTypes.success, text: 'E-postbekreftelse er sendt' };
    eventBus.emitter.emit(Events.alert, alertContent);
}

async function submit() {
    const validation = await form.value?.validate();
    if (!validation?.valid) return;

    loading.value.submit = true;
    try {
        await store.updateProfile();
        const alertContent = { type: AlertTypes.success, text: 'Profilen ble oppdatert' };
        eventBus.emitter.emit(Events.alert, alertContent);
        await auditStore.loadCurrentProfileEvents();
        await store.loadCurrentProfileUser();
    } catch (err: any) {
        loading.value.submit = false;
        const data = err.response.data;
        const errors = getErrors(data);

        if (errors !== null) {
            eventBus.emitter.emit(Events.alert, errors);
        } else {
            throw err;
        }
    }
    loading.value.submit = false;
}

function openChangePasswordDialog() {
    changePasswordDialog.value = true;
}

async function changePassword(password: string) {
    await store.changePassword(password);
    const alertContent = { type: AlertTypes.success, text: 'Passord er endret' };
    eventBus.emitter.emit(Events.alert, alertContent);
}

async function sendForgotPassword() {
    await store.sendForgotPassword();
    const alertContent = { type: AlertTypes.success, text: 'E-post for tilbakestilling av passord er sendt' };
    eventBus.emitter.emit(Events.alert, alertContent);
}

async function deleteProfile() {
    confirmDialog.value = false;
    await store.deleteProfile();
    const alertContent = { type: AlertTypes.success, text: 'Profilen er slettet' };
    eventBus.emitter.emit(Events.alert, alertContent);
    await router.push({ name: ProfileRels.GetProfiles });
}

function formatDate(date?: Date) {
    if (!date) return '';
    return formatters.toLocaleDateTime(date);
}
</script>

<style scoped>
.profile-loader {
    width: 100%;
    background: transparent;
    max-width: 700px;
}

.profile-form {
    max-width: 700px;
}

.loading {
    opacity: 0.5;
}

.controls {
    display: flex;
    justify-content: space-between;
}

.profile-edit-window {
    padding: 12px 1px;
}

.menu-button {
    margin-bottom: 6px;
    float: right;
}
</style>
