import { defineStore } from 'pinia';
import { useProfilesStore } from '@/features/profiles/profilesStore';
import { useAxios } from '@/features/shared/axios';
import { ProfileRels } from '@/features/profiles/rels';
import { getLink } from '@/features/shared/LinkHelper';
import { IssueSummary } from '@/features/issues/issuesModels';

export const useIssuesStore = defineStore('issues', {
    state: () => ({
        currentIssues: new Array<IssueSummary>()
    }),
    actions: {
        async loadIssues() {
            const profilesStore = useProfilesStore();
            const axios = useAxios();
            const link = getLink(ProfileRels.GetIssues, profilesStore.getProfileLinks);
            const response = await axios.requestByLink(link);
            this.currentIssues = response.data.data;
        }
    }
});
