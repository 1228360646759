import ProfilesList from '@/features/profiles/ProfilesList.vue';
import { ProfileRels } from '@/features/profiles/rels';
import ProfileEdit from '@/features/profiles/ProfileEdit.vue';
import ProfileCreate from '@/features/profiles/ProfileCreate.vue';

export const ProfileRoutes = [
    {
        path: '/',
        redirect: '/profiles'
    },
    {
        path: '/profiles',
        name: ProfileRels.GetProfiles,
        component: ProfilesList
    },
    {
        path: '/profiles/:id',
        name: ProfileRels.UpdateProfile,
        component: ProfileEdit
    },
    {
        path: '/profiles/create',
        name: ProfileRels.CreateProfile,
        component: ProfileCreate
    }
];
