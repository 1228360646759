﻿<template>
    <iframe id="bff-silent-login" :src="loginSrc" hidden></iframe>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useUserStore } from '@/features/authentication/userStore';

const loginSrc = ref<string>();

onMounted(() => {
    const store = useUserStore();

    if (store.user.isLoggedIn) return;

    loginSrc.value = '/bff/silent-login';

    console.log('Loading silent login');

    window.addEventListener('message', e => {
        console.log('Starting silent login');

        if (e.data && e.data.source === 'bff-silent-login' && e.data.isLoggedIn) {
            // we now have a user logged in silently, so reload this window

            console.log('Reloading silent login');
            window.location.reload();
        } else {
            console.log('Logging in');

            const loginUrl = '/bff/login?returnUrl=' + window.location.pathname;
            window.location.replace(loginUrl);
        }
    });
});
</script>
