<template>
    <div class="v-select">
        <div class="v-input__control">
            <div class="v-field v-field--active v-field--variant-filled">
                <div class="v-field__overlay"></div>
                <label class="v-label v-field-label v-field-label--floating" for="input-select">{{ props.label }}</label>
                <select class="select" @change="update">
                    <option
                        v-for="item in props.items"
                        :key="item.code"
                        :selected="item.code === selected"
                        :value="item.code"
                        :name="item.name"
                    >
                        {{ item.name }}
                    </option>
                </select>
                <div class="v-field__outline"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
interface SelectItem {
    name: string;
    code: string;
}

interface Props {
    items: Array<SelectItem>;
    selected?: string;
    label: string;
    returnObject?: boolean;
}

type Emits = (e: 'update:modelValue', value: string | SelectItem) => void;

const props = withDefaults(defineProps<Props>(), { returnObject: () => false });
const emit = defineEmits<Emits>();

function update(e: any) {
    const selectedItem = props.returnObject ? findSelectedItem(e.target.value) : e.target.value;
    emit('update:modelValue', selectedItem);
}

function findSelectedItem(value: string) {
    return props.items.find(item => item.code === value);
}
</script>

<style scoped>
.select {
    min-height: 56px;
    outline: none;
    padding-left: 16px;
    padding-top: 18px;
    cursor: pointer;
}
</style>
