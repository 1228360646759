export function isRequired(value: string) {
    if (value) return true;
    return 'Feltet er påkrevet.';
}

export function emailFormat(value: string) {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return true;
    return 'E-post må være gyldig.';
}

export function phoneFormat(value: string) {
    if (/^\d*$/.test(value)) return true;
    return 'Telefonnummeret må kun inneholde numre.';
}
