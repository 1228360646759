<template>
    <div class="phone">
        <select-item v-model="model.countryCode" :items="phoneCodes" :selected="model.countryCode" label="Land" />
        <v-text-field v-model="model.number" label="Telefon" :rules="rules"></v-text-field>
    </div>
</template>

<script lang="ts" setup>
import SelectItem from './SelectItem.vue';
import { useVModel } from '@vueuse/core';
import { isRequired, phoneFormat } from '../formValidationRules';
import { Phone, PhoneCountryCode } from '../models';

interface Props {
    modelValue: Phone;
    phoneCodes: Array<PhoneCountryCode>;
}

type Emits = (e: 'update:modelValue', value: Phone) => void;

const rules = [isRequired, phoneFormat];

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const model = useVModel(props, 'modelValue', emit);
</script>

<style scoped>
.phone {
    display: grid;
    grid-template-columns: 32% 68%;
}
</style>
