import axios, { AxiosResponse, Method, RawAxiosRequestConfig } from 'axios';
import { Link } from '@/features/shared/models';

export const useAxios = function () {
    const instance = axios.create();
    instance.defaults.headers.get['x-csrf'] = 1;
    instance.defaults.headers.post['x-csrf'] = 1;
    instance.defaults.headers.put['x-csrf'] = 1;
    instance.defaults.headers.delete['x-csrf'] = 1;
    instance.defaults.headers.common['Accept-Language'] = 'no';

    const extensions = {
        requestByLink: function <T = any, R = AxiosResponse<T>, D = any>(
            link: Link,
            config?: RawAxiosRequestConfig<D> | undefined
        ): Promise<R> {
            const requestConfig = {
                method: <Method>link.method,
                url: link.href
            };

            return instance.request({ ...requestConfig, ...config });
        }
    };

    return { instance, ...extensions };
};
