<template>
    <v-row>
        <v-col class="access-denied">Access denied</v-col>
    </v-row>
</template>

<style lang="scss" scoped>
.access-denied {
    margin: 20px 60px;
    font-size: 56px;
}
</style>
