import { defineStore } from 'pinia';
import { BaseResource, Link, ListResource, PhoneCountryCode } from '@/features/shared/models';
import { useAxios } from '@/features/shared/axios';
import { CommonRels } from './rels';

export const useCommonStore = defineStore('common', {
    state: () => ({
        links: new Array<Link>(),
        countryPhoneCodes: new Array<PhoneCountryCode>()
    }),
    getters: {
        getLink(state) {
            return (rel: string): Link | undefined => state.links.find(x => x.rel === rel);
        }
    },
    actions: {
        async loadLinks() {
            const axios = useAxios();
            const response = await axios.instance.get<BaseResource>('/api');
            this.links = response.data.links;
        },
        async getCountryPhoneCodes() {
            if (!this.countryPhoneCodes.length) {
                await this.loadCountryPhoneCodes();
            }

            return this.countryPhoneCodes;
        },
        async loadCountryPhoneCodes() {
            const common = useCommonStore();
            const axios = useAxios();
            const link = common.getLink(CommonRels.GetCountryCodes);

            if (!link) throw new Error(`${CommonRels.GetCountryCodes} link not found`);

            const response = await axios.requestByLink<ListResource<PhoneCountryCode>>(link);

            this.countryPhoneCodes = response.data.data;
        }
    }
});
